import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

import "../styles/components/poolCollectors.styl"

import { StaticImage } from "gatsby-plugin-image"
import CallToActionButton from "../components/CallToActionButton"

export default function colectoresParaAlberca() {
  return (
    <Layout>
      <Seo title="Colectores para alberca" />
      <hero className="colectors c-main">
        <h1 className="c-main__title">Colectores para alberca</h1>
        <div className="c-main__pool-heater">
          <StaticImage
            src={"../images/pool/pool-heater.png"}
            alt="Calentador solar PHOTON"
          />
        </div>
        <div className="c-main__logo-image">
          <StaticImage src={"../images/pool/oku-logo.png"} alt="" />
        </div>
        <div className="c-main__text">
          <p>
            Un sistema de ingeniería realizado a medida para cada cliente hace
            de esta solución la mejor opción, ademas de la correcta instalación
            garantizan la satisfacción del cliente. Dentro del sistema se
            incluye un control de calefacción digital inteligente. En el cual el
            usuario puede fácilmente cambiar la temperatura deseada.
          </p>
        </div>
      </hero>

      <div className="outstanding-features">
        <div
          className="outstanding-features__feature feature"
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-easing="ease"
        >
          <h3 className="feature__title">Panel Solar Transitable</h3>
          <p>
            Fundido en una sola pieza prácticamente irrompible. Carente de
            corrosión y resistente a todos los tratamientos químicos
          </p>
        </div>
        <div
          className="outstanding-features__feature feature"
          data-sal="slide-up"
          data-sal-delay="250"
          data-sal-easing="ease"
        >
          <h3 className="feature__title">Alta eficacia</h3>

          <p>
            Hasta 0.8 Kw/h/m2. Rendimiento garantizado. Mayor aumento de
            temperatura con menor supercie de paneles.
          </p>
        </div>
        <div
          className="outstanding-features__feature feature"
          data-sal="slide-up"
          data-sal-delay="350"
          data-sal-easing="ease"
        >
          <h3 className="feature__title">
            Resistente a los productos químicos
          </h3>
          <p>
            Completamente resistente a los productos químicos que nos podemos
            encontrar en cualquier instalación.
          </p>
        </div>
      </div>
      <section className="installation">
        <h2 className="installation__title">5 Años de Garantía</h2>
        <h2 className="installation__desc">
          DIAGRAMA DE <br /> INSTALACIÓN
        </h2>
        <div className="installation__figure">
          <StaticImage
            src={"../images/pool/installation.png"}
            alt="Diagrama de instalacion calentador solar PHOTON"
            className="installation__image"
          />
        </div>
      </section>
      <CallToActionButton />
    </Layout>
  )
}
